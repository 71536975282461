* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif JP', serif;
  font-size: 1rem;
  color: #2C2D31;
  background-color: #f1f1f1;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

button:focus,
select:focus {
  outline: none;
}

.app {
  margin-top: 3.5rem;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.description {
  margin-top: 2rem;
  font-size: 1.2rem;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .app {
    margin-top: 2.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .description {
    margin-top: 1.2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    font-size: 1rem;
  }
}

.name {
  margin-top: 3rem;
  text-align: center;
}

.name p {
  font-size: 1.8rem;
  letter-spacing: 0.15rem;
}

@media only screen and (max-width: 767px) {
  .name {
    margin-top: 2rem;
  }

  .name p {
    font-size: 1.3rem;
  }
}

.remaining-attempts {
  margin-top: 2.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.keyboard {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keyboard-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyboard-row {
  margin-bottom: 0.5rem;
}

.letter-button {
  margin-right: 1px;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  width: 60px; 
  color: #f1f1f1;
  background-color: #4a7ab4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 200ms ease-out;
}

.letter-button:hover {
  background-color: #6793c9;
}

.letter-button.disabled {
  background-color: #787f88;
  cursor: not-allowed;
}

.letter-button.disabled:hover {
  background-color: #787f88;
}

.letter-button.disabled.good {
  background-color: #47a89b;
}

.letter-button.disabled.good:hover {
  background-color: #47a89b;
}

@media only screen and (max-width: 767px) {
  .remaining-attempts {
    margin-top: 2rem;
  }
  .keyboard-row {
    margin-bottom: 0.1rem;
  }
}

.game-restart {
  text-align: center;
}

.restart-button {
  margin-top: 1rem;
  padding: 0.6rem 1rem 0.8rem;
  font-family: 'Noto Serif JP', serif;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  cursor: pointer;
  color: #f1f1f1;
  background-color: #4a7ab4;
  border: none;
  border-radius: 4px;
  transition: 250ms ease-out;
}

.restart-button:hover {
  background-color: #6793c9;
}
